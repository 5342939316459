import {
  styled,
  selectTheme,
  ButtonSecondary,
  Typography,
} from "@creditas-ui/react";

const getPaddingLeft = (width: number): string =>
  `calc((100vw - ${width}px) / 2)`;

export const Container = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;

  width: 100vw;
  padding-left: ${getPaddingLeft(1040)};

  @media (min-width: 905px) {
    min-height: 90vh;
  }
  @media (max-width: 1439px) {
    padding-left: 200px;
  }
  @media (max-width: 1239px) {
    padding-left: ${getPaddingLeft(840)};
  }
  @media (max-width: 904px) {
    display: flex;
    padding: 0 32px;
    flex-direction: column-reverse;
  }
  @media (max-width: 599px) {
    padding: 0 16px;
  }
`;

export const Title = styled(Typography)`
  margin: 32px 0 16px;
`;

export const Subtitle = styled(Typography)`
  color: ${selectTheme("colors.neutral.60")};
`;

export const Description = styled(Typography)`
  margin-top: 32px;
  color: ${selectTheme("colors.neutral.60")};
`;

export const Link = styled(ButtonSecondary)`
  margin-top: 24px;
`;
