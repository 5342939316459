import { line } from "d3-shape";
import { scaleLinear } from "d3-scale";
import { CoordsType } from "../types/Insect.types";

export const convertPathToData = (path: string): CoordsType[] => {
  const pathElem = document.createElementNS(
    "http://www.w3.org/2000/svg",
    "path"
  );
  pathElem.setAttribute("d", path);

  const pathData: CoordsType[] = [];
  for (let p = 0; p < pathElem.getTotalLength(); p++) {
    const { x, y } = pathElem.getPointAtLength(p);
    pathData.push([x, y]);
  }
  return pathData;
};

export const getScaledCoords = (
  pathData: CoordsType[],
  containerElement: SVGSVGElement | null
): CoordsType[] => {
  if (!containerElement) return [];
  const getMaximums = (pathData: CoordsType[]): CoordsType => {
    const X_POINTS = pathData.map((point) => point[0]);
    const Y_POINTS = pathData.map((point) => point[1]);

    return [Math.max(...X_POINTS), Math.max(...Y_POINTS)];
  };
  const width = containerElement.clientWidth;
  const height = containerElement.clientHeight;

  const maximums = getMaximums(pathData);
  const [maxWidth, maxHeight] = maximums;

  const xScale = scaleLinear().domain([0, maxWidth]).range([0, width]);
  const yScale = scaleLinear().domain([0, maxHeight]).range([0, height]);

  const scaledPoints: CoordsType[] = pathData.map((point) => [
    xScale(point[0]),
    yScale(point[1]),
  ]);

  return scaledPoints;
};

export const makeResponsivePath = (
  pathData: CoordsType[],
  containerElement: SVGSVGElement | null
): string | null => {
  if (!containerElement) return null;
  const scaledPoints = getScaledCoords(pathData, containerElement);
  return line()(scaledPoints);
};
