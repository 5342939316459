import React from "react";
import { ThemeProvider, standard, Toaster } from "@creditas-ui/react";
import { Global } from "@creditas/global";
import { TrackingProvider, tracking } from "./tracking";

import NotFound from "./components/NotFound.component";

import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next, I18nextProvider } from "react-i18next";
import esMX from "./locales/es-mx";

export default function Root(props) {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources: {
        "es-MX": esMX,
      },
      lng: props.language,
      fallbackLng: "es-MX",

      interpolation: {
        escapeValue: false,
      },
    });

  return (
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={standard}>
        <Global />
        <Toaster />
        <NotFound {...props} />
      </ThemeProvider>
    </I18nextProvider>
  );
}
