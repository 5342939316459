import React from "react";
import InsectAnimation from "./InsectAnimation.component";
import { useTranslation } from "react-i18next";
import { navigateWithParams } from "../utils/url";
import {
  Container,
  Title,
  Subtitle,
  Description,
  Link,
} from "./NotFound.styles";
import { INotFound } from "./NotFound.types";
import { FAQS_URL } from "./NotFound.constants";

const NotFound = ({ product }: INotFound) => {
  const { t } = useTranslation();
  return (
    <Container>
      <div>
        <Title variant={{ "2xl": "heading2xlBold", _: "headingMdBold" }}>
          {t("page:exclamation")}
        </Title>
        <Subtitle
          variant={{
            "2xl": "headingLgLight",
            _: "headingMdLight",
          }}
        >
          {t("page:title")}
        </Subtitle>
        <Description
          variant={{
            "2xl": "bodyLgRegular",
            _: "bodyMdRegular",
          }}
        >
          {t("page:codeError")}
        </Description>
        <Description
          variant={{
            "2xl": "bodyLgRegular",
            _: "bodyMdRegular",
          }}
        >
          {t("page:links")}
        </Description>
        <Link onClick={(): void => navigateWithParams(product.landingPageURL)}>
          {t("page:goLandingPage", { product })}
        </Link>
        <Link onClick={(): void => navigateWithParams(FAQS_URL)}>
          {t("page:goFrequentQuestions")}
        </Link>
      </div>
      <InsectAnimation />
    </Container>
  );
};

export default NotFound;
