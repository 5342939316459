import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import Root from "./root.component";

const domElementGetter = (domElement: string): HTMLElement => {
  let element = document.getElementById(domElement);
  if (!element) {
    element = document.createElement("div");
    element.id = domElement;
    document.body.appendChild(element);
  }
  return element;
};

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
  domElementGetter: (props) => domElementGetter(props.domElementId),
});

export const { bootstrap, mount, unmount } = lifecycles;
