import { styled } from "@creditas-ui/react";

export const InsectContainer = styled.svg<{
  offsetPath?: string | null;
  isIncompatibleBrowser: boolean;
  isMobile: boolean;
}>`
  width: 100%;
  height: 100%;
  overflow: visible;

  @media (max-width: 904px) {
    height: 25vh;
  }

  .insect {
    ${({ isIncompatibleBrowser, offsetPath, isMobile }): string =>
      isIncompatibleBrowser
        ? `
        transition: 50ms;
        transform: scale(0);`
        : `
      @keyframes move {
        48% {
          transform: rotate(0deg);
          offset-distance: 100%;
        }
        50% {
          transform: rotate(-180deg);
        }
        98% {
          transform: rotate(-180deg);
          offset-distance: 0%;
        }
        100% {
          transform: rotate(-360deg);
        }
      }
      offset-path: path('${offsetPath ? offsetPath : ""}');
      animation: move ${isMobile ? 8 : 32}s infinite linear;`}
  }
`;
