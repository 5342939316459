import React from "react";
import { InsectContainer } from "./InsectAnimation.styles";
import InsectSvg from "./InsectSvg.component";
import { useInsect } from "../hooks/useInsect.hook";

const InsectAnimation: React.FC = () => {
  const {
    responsivePath,
    insectRef,
    containerSvgRef,
    isIncompatibleBrowser,
    isMobile,
  } = useInsect();
  return (
    <InsectContainer
      offsetPath={responsivePath}
      isIncompatibleBrowser={isIncompatibleBrowser}
      isMobile={isMobile}
      ref={containerSvgRef}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <InsectSvg isMobile={isMobile} insectRef={insectRef} />
    </InsectContainer>
  );
};

export default InsectAnimation;
