import { useState, useRef, useEffect, useLayoutEffect } from "react";
import {
  makeResponsivePath,
  convertPathToData,
  getScaledCoords,
} from "../utils/makeResponsivePath";
import { insectTrackPath } from "../constants/Insect.constants";
import {
  UseInsectReturnInterface,
  TrackPathInterface,
  CoordsType,
} from "../types/Insect.types";

const isIncompatibleBrowser = !CSS.supports(
  "offset-path",
  `path('${insectTrackPath.mobile}')`
);

export const useInsect = (): UseInsectReturnInterface => {
  const [trackPath, setTrackPath] = useState<TrackPathInterface>({
    pathData: [],
    responsivePath: null,
    scaledCoords: [],
  });
  const [, setCurrentCoordsIndex] = useState(0);
  const containerSvgRef = useRef<SVGSVGElement>(null);
  const insectRef = useRef<SVGGElement>(null);
  const [prevWidth, setPrevWidth] = useState(0);

  useEffect(() => {
    const makePath = (): void => {
      const width = document.body.clientWidth;
      const isMobile = width < 905;
      const wasLastMobile = prevWidth < 905;
      if (prevWidth !== width) {
        setTrackPath((prevState) => {
          let pathData = [];
          if (prevState.pathData.length !== 0 && wasLastMobile === isMobile) {
            pathData = prevState.pathData;
          } else {
            pathData = convertPathToData(
              isMobile ? insectTrackPath.mobile : insectTrackPath.desktop
            );
          }
          let responsivePath = null;
          let scaledCoords: CoordsType[] = [];
          if (!isIncompatibleBrowser) {
            responsivePath = makeResponsivePath(
              pathData,
              containerSvgRef.current
            );
          } else {
            scaledCoords = getScaledCoords(pathData, containerSvgRef.current);
            setCurrentCoordsIndex(0);
          }
          return {
            pathData,
            responsivePath,
            scaledCoords,
          };
        });
        setPrevWidth(width);
      }
    };
    if (containerSvgRef.current) {
      makePath();
      window.addEventListener("resize", makePath);
      return (): void => window.removeEventListener("resize", makePath);
    }
  }, [containerSvgRef, prevWidth]);

  useLayoutEffect(() => {
    if (isIncompatibleBrowser && insectRef.current) {
      const timer = setInterval(
        (): void =>
          setCurrentCoordsIndex((prevIndex) => {
            if (prevIndex + 1 === trackPath.scaledCoords.length) {
              insectRef.current?.style.setProperty("transform", "scale(0)");
              return 0;
            } else {
              const [x, y] = trackPath.scaledCoords[prevIndex];
              const [nextX, nextY] = trackPath.scaledCoords[prevIndex + 1];
              const angle = Math.atan((nextY - y) / (nextX - x));
              insectRef.current?.style.setProperty(
                "transform",
                `translate(${x}px, ${y}px) rotate(${angle}rad) scale(${
                  prevIndex !== 0 ? 1 : 0
                })`
              );
            }
            return prevIndex + 1;
          }),
        prevWidth < 905 ? 15 : 10
      );
      return (): void => clearInterval(timer);
    }
  }, [trackPath, insectRef, prevWidth]);

  return {
    insectRef,
    containerSvgRef,
    isIncompatibleBrowser,
    containerWidth: prevWidth,
    responsivePath: trackPath.responsivePath,
    isMobile: prevWidth < 905,
  };
};
