import React, { RefObject } from "react";

const InsectSvgDesktop: React.FC = () => (
  <>
    <path
      opacity="0.25"
      d="M5.32589 35.6396C-11.3123 61.1296 20.4694 72.888 40.5649 72.888C60.332 72.888 82.6695 65.4301 82.6695 42.4643C82.7024 -16.5739 33.8719 -8.14577 5.32589 35.6396Z"
      fill="#49E295"
    />
    <path
      id="topLeftLeg"
      d="M65 15L57 19L53.332 24.332"
      stroke="#1F2D27"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <animate
      xlinkHref="#topLeftLeg"
      attributeName="d"
      attributeType="XML"
      values="M68 15L57 19L53.332 24.332;M60 10L57 14L53.332 24.332;M68 15L57 19L53.332 24.332"
      dur="1s"
      repeatCount="indefinite"
      keyTimes="0;.5;1"
    />
    <path
      d="M25 40.776L25 37.22C25 34.0374 26.2643 30.9852 28.5147 28.7347C30.7652 26.4843 33.8174 25.22 37 25.22L49 25.22C51.1217 25.22 53.1566 26.0629 54.6569 27.5631C56.1571 29.0634 57 31.0983 57 33.22L57 44.776C57 46.8977 56.1571 48.9326 54.6569 50.4329C53.1566 51.9331 51.1217 52.776 49 52.776L37 52.776C33.8174 52.776 30.7652 51.5117 28.5147 49.2613C26.2643 47.0108 25 43.9586 25 40.776V40.776Z"
      stroke="#1F2D27"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      id="botLeftLeg"
      d="M25 15L33 19L36.668 24.332"
      stroke="#1F2D27"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <animate
      xlinkHref="#botLeftLeg"
      attributeName="d"
      attributeType="XML"
      values="M25 15L33 19L36.668 24.332;M22 20L33 19L36.668 24.332;M25 15L33 19L36.668 24.332"
      dur="0.75s"
      repeatCount="indefinite"
      keyTimes="0;.5;1"
    />
    <path
      id="botRightLeg"
      d="M25 63L33 59L36.668 53.668"
      stroke="#1F2D27"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <animate
      xlinkHref="#botRightLeg"
      attributeName="d"
      attributeType="XML"
      values="M25 63L33 59L36.668 53.668;M20 63L33 59L36.668 53.668;M25 63L33 59L36.668 53.668"
      dur="0.75s"
      repeatCount="indefinite"
      keyTimes="0;.5;1"
    />
    <path
      d="M57 47L57 31L65 31C66.0609 31 67.0783 31.4214 67.8284 32.1716C68.5786 32.9217 69 33.9391 69 35L69 43C69 44.0609 68.5786 45.0783 67.8284 45.8284C67.0783 46.5786 66.0609 47 65 47L57 47Z"
      stroke="#1F2D27"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      id="topRightLeg"
      d="M65 63L57 59L53.332 53.668"
      stroke="#1F2D27"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <animate
      xlinkHref="#topRightLeg"
      attributeName="d"
      attributeType="XML"
      values="M68 63L57 59L53.332 53.668;M68 54L58 54L53.332 53.668;M68 63L57 59L53.332 53.668"
      dur="1s"
      repeatCount="indefinite"
      keyTimes="0;.5;1"
    />
    <path
      id="centerLeftLeg"
      d="M50 11L45 23.444"
      stroke="#1F2D27"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <animate
      xlinkHref="#centerLeftLeg"
      attributeName="d"
      attributeType="XML"
      from="M50 9L45 23.444"
      to="M40 11L45 23.444"
      dur="1s"
      repeatCount="indefinite"
    />
    <animate
      xlinkHref="#centerLeftLeg"
      attributeName="d"
      attributeType="XML"
      values="M50 9L45 23.444;M40 11L45 23.444;M50 9L45 23.444"
      dur="1s"
      repeatCount="indefinite"
      keyTimes="0;.5;1"
    />
    <path
      id="centerRightLeg"
      d="M45 54.556L40 67"
      stroke="#1F2D27"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <animate
      xlinkHref="#centerRightLeg"
      attributeName="d"
      attributeType="XML"
      values="M45 54.556L40 67;M45 54.556L48 67;M45 54.556L40 67"
      dur="1s"
      repeatCount="indefinite"
      keyTimes="0;.5;1"
    />
  </>
);

const InsectSvgMobile: React.FC = () => (
  <>
    <path
      opacity="0.25"
      d="M3.72447 28.1201C-9.58619 48.513 15.8394 57.92 31.916 57.92C47.7298 57.92 65.6 51.9535 65.6 33.5802C65.6263 -13.6523 26.5615 -6.90949 3.72447 28.1201Z"
      fill="#49E295"
    />
    <path
      d="M51.4642 11.6078L45.0642 14.808L42.1297 19.0737"
      stroke="#1F2D27"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.4639 32.2294L19.4639 29.3845C19.4639 26.8383 20.4753 24.3964 22.2757 22.596C24.0761 20.7956 26.5179 19.7841 29.064 19.7841L38.6641 19.7841C40.3615 19.7841 41.9894 20.4585 43.1896 21.6587C44.3899 22.859 45.0641 24.4869 45.0641 26.1844L45.0641 35.4296C45.0641 37.127 44.3899 38.7549 43.1896 39.9552C41.9894 41.1555 40.3615 41.8298 38.6641 41.8298L29.064 41.8298C26.5179 41.8298 24.0761 40.8183 22.2757 39.0179C20.4753 37.2175 19.4639 34.7756 19.4639 32.2294V32.2294Z"
      stroke="#1F2D27"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.4639 11.6078L25.864 14.808L28.7984 19.0737"
      stroke="#1F2D27"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.4639 50.0093L25.864 46.8092L28.7984 42.5434"
      stroke="#1F2D27"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M45.0641 37.2088L45.0641 24.4084L51.4642 24.4084C52.3129 24.4084 53.1269 24.7455 53.727 25.3457C54.3271 25.9458 54.6642 26.7598 54.6642 27.6085L54.6642 34.0087C54.6642 34.8575 54.3271 35.6714 53.727 36.2716C53.1269 36.8717 52.3129 37.2088 51.4642 37.2088L45.0641 37.2088Z"
      stroke="#1F2D27"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.4642 50.0093L45.0642 46.8092L42.1297 42.5434"
      stroke="#1F2D27"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.4641 8.40771L35.4641 18.3633"
      stroke="#1F2D27"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.4641 43.2539L35.4641 53.2095"
      stroke="#1F2D27"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
);

const InsectSvg: React.FC<{
  isMobile: boolean;
  insectRef: RefObject<SVGGElement>;
}> = ({
  isMobile,
  insectRef,
}: {
  isMobile: boolean;
  insectRef: RefObject<SVGGElement>;
}) => (
  <g className="insect" ref={insectRef}>
    {isMobile ? <InsectSvgMobile /> : <InsectSvgDesktop />}
  </g>
);

export default InsectSvg;
